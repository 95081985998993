.pagination {
  bottom: 0;
  margin: auto;
  margin-bottom: 1.6rem;
  justify-content: center;
}

.page-link {
  background-color: $secondary-background;
  border: none;
  color: $text-color;
}

.page-link:hover {
  background-color: darken($secondary-background, 10%);
  color: $text-color;
}

.page-link:focus {
  box-shadow: none;
}

.current-page {
  background-color: darken($secondary-background, 5%);
}
