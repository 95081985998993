.object-list {
  height: calc(100vh - 69.61px /* header */);
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.object-list__spnr {
  display: grid;
  height: 50px;
  margin: auto;
  margin-top: 1.6rem;
  width: 50px;
}

.list-item {
  background: darken($secondary-background, 1%);
  border-radius: 0.5rem;
  color: $text-color;
  margin-bottom: 1.2rem;
  padding: 0.9rem;
  box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.15);
}

.list-item-red {
  color: rgb(236, 103, 103) !important;
}

.list-item__p1 {
  color: #484b50;
  font-size: 13px;
  font-weight: 500;
}

.list-item__p2 {
  color: $text-color;
  font-size: 13px;
  font-weight: 500;
}

.list-item__verified-badge {
  color: rgba(223, 190, 83, 0.788);
}

.list-item__pre {
  background: darken($secondary-background, 8%);
  color: rgb(37, 37, 37);
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas, 'Liberation Mono', monospace;
  font-size: 0.9rem;
  font-weight: bolder;
  padding: 10px;
  border-radius: 0.375rem;
  box-shadow: $box-shadow-black-sm;
}
