.custom-input {
  //   background-color: $input-background !important;
  //   color: $text-color !important;
}

.css-13cymwt-control {
  border-width: 0px !important;
  //   background-color: $input-background !important;
  //   color: $text-color !important;
}

.syntax-highlight {
  background-color: #23434e !important;
  color: #c9c8c8 !important;
  font-family: Consolas, monospace !important;
  font-size: 0.9rem !important;
}

.syntax-highlight-linecount {
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #031a22;
  border-radius: 0.375rem;
  background: #031a22;
  font-family: Consolas, monospace;
  color: #c9c8c8;
}

.ws-no-wrap {
  white-space: nowrap !important;
}
