.table td,
.table th {
  border-top: none;
  padding: 0.75rem;
  vertical-align: top;
}

.table-hover tbody tr:hover {
  background-color: darken($secondary-background, 20%);
}

.parameters-table-header {
  cursor: pointer;
}

.parameters-table-row {
  cursor: pointer;
  font-size: 12px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.parameters-table-row:hover {
  background: darken($secondary-background, 3%);
  box-shadow: inset 2px 0px 0px 0px darken($secondary-background, 30%);
}
