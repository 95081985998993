// Root
html {
  font-size: $font-size;
  font-feature-settings: normal;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $main-background !important;
  overflow: overlay;
  min-height: 100vh;
  color: $text-color;
}

#root {
  min-height: 100vh;
  position: relative;
}

section {
  width: auto;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

// H
h1,
h2,
h3,
h4 {
  color: $h-color;
}
h1 {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
}

h2 {
  font-weight: $h2-font-weight;
}
h3 {
  font-weight: $h3-font-weight;
}
h4 {
  font-weight: $h4-font-weight;
}
h5 {
  font-weight: $h5-font-weight;
}

// HR
hr {
  border: none;
  background-color: $hr-color !important;
  height: 2px;
  width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Page
.page {
  padding-left: $page-padding !important;
  padding-right: $page-padding !important;
}

.below-header {
  padding-top: calc($page-padding + $header-height) !important;
}

// Margin
.no-m {
  margin: 0px;
}

// Padding
.no-p {
  padding: 0px !important;
}

.p-b-s {
  padding-bottom: calc($page-padding / 2);
}

.p-b-m {
  padding-bottom: $page-padding;
}

.p-b-l {
  padding-bottom: calc($page-padding * 2);
}

.p-b-xl {
  padding-bottom: calc($page-padding * 3);
}

.p-t-s {
  padding-top: calc($page-padding / 2);
}

.p-t-m {
  padding-top: $page-padding;
}

.p-t-l {
  padding-top: calc($page-padding * 2);
}

.p-t-xl {
  padding-top: calc($page-padding * 3);
}

.p-t-xxl {
  padding-top: calc($page-padding * 4);
}

// Text
.t-align-c {
  text-align: center;
}

.t-align-r {
  text-align: right;
}

.text-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #8d37d8;
  --tw-gradient-to: #8d37d800;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #4469f300;
  --tw-gradient-stops: var(--tw-gradient-from), #4469f3, var(--tw-gradient-to);
  --tw-gradient-to: #4469f3;
  -webkit-background-clip: text;
  background-clip: text;
  color: #fff0;
}

// Scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: darken($header-background, 0%);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: darken($header-background, 5%);
}
::-webkit-scrollbar-track {
  background: $header-background;
}
