.modal,
.popover,
.offcanvas {
  opacity: 0.92;
  font-family: inherit;
}

.modal-content,
.popover,
.popover-header,
.offcanvas {
  background-color: $main-background;
}

.modal-title,
.popover-header,
.popover-body,
.offcanvas {
  color: $text-color;
}

.modal-footer {
  border-top: 1px solid darken($main-background, 5%);
}

.modal-header,
.popover-header {
  border-bottom: 1px solid darken($main-background, 5%);
}

.fade-in {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}
