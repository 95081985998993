// Fonts
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600&display=swap');

// Base
$header-height: 64.375px;
$header-position: fixed;
$page-padding: 24px;
$font-size: 15px;
// $font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
//   'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family: 'Plus Jakarta Sans', sans-serif;
$h1-font-size: 45px;
$h1-font-weight: 700;
$h2-font-weight: 700;
$h3-font-weight: 700;
$h4-font-weight: 500;
$h5-font-weight: 500;
$general-opacity: 0.95;
$border-radius: 15px;

// Colours
$box-shadow-input: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$box-shadow-black: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
$box-shadow-black-sm: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$box-shadow-black-lg: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
$box-shadow-black-xl: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
$text-shadow: 1px 1px 10px #000000;
$text-shadow-sm: 1px 1px #000000;
$text-white-color: #cccccc;

// Colours (Light mode)
$main-background: rgba(244, 245, 253, 1);
$main-background-0-9: rgb(244, 245, 253, 0.9);
$main-background-0-7: rgb(244, 245, 253, 0.7);
$secondary-background: rgb(238, 243, 252);
$header-background: rgba(14, 28, 34, 0.6);
$input-background: rgba(166, 191, 230, 0.76);
$hr-color: rgb(170, 102, 0);
$h-color: rgb(66, 77, 95);
$text-color: #252525;
